import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  field: string;
  headerName: string;
  handleShowFilter: (field: string) => void;
  filterRef: React.RefObject<HTMLDivElement>;
  activeFilterHeader: string | null;
  handleProgressRangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetFilter: () => void;
};

const ColumnHeaderRender = ({
  field,
  headerName,
  handleShowFilter,
  filterRef,
  activeFilterHeader,
  handleProgressRangeFilter,
  handleResetFilter,
}: Props) => {
  const showFilters = activeFilterHeader === field;

  return (
    <Stack
      ref={filterRef}
      onDoubleClick={() => {
        handleResetFilter();
        handleShowFilter(field);
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {showFilters ? (
          <Iconify
            icon="clarity:remove-line"
            width={24}
            height={24}
            color="red"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleResetFilter();
              handleShowFilter(field);
            }}
          />
        ) : (
          <Typography
            variant="body2"
            noWrap
            sx={{ width: showFilters ? '20%' : '100%' }}
          >
            {headerName}
          </Typography>
        )}
        {showFilters && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ width: '80%' }}
          >
            <TextField
              type="number"
              name="min_percentage"
              onChange={handleProgressRangeFilter}
              placeholder="min"
              size="small"
            />
            <TextField
              type="number"
              name="max_percentage"
              onChange={handleProgressRangeFilter}
              placeholder="max"
              size="small"
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ColumnHeaderRender;
