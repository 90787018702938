import { Button, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  deDE,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/lib/hooks/useLocales';
import EmptyContent from '#/components/shared/EmptyContent';

type Props = {
  gridData: any;
  isLoading: boolean;
  rowCount: number;
  columnVisibilityModel: GridColumnVisibilityModel;
  onColumnVisibilityModelChange: (newModel: GridColumnVisibilityModel) => void;
  onPaginationModelChange: (newModel: GridPaginationModel) => void;
  onRowSelectionModelChange: (newSelectionModel: any) => void;
  rowSelectionModel: any;
  columns: any;
  paginationModel: GridPaginationModel;
  isEmpty: boolean;
  isInvitedByConsultant: boolean | undefined;
  handleOpenConfirmUnassignment: () => void;
  selectedRows: any;
  confirmRows: any;
  getTogglableColumns: () => any;
  onSortBy: (model: GridSortModel) => void;
};

export default function UsersDataGrid({
  gridData,
  isLoading,
  rowCount,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  onPaginationModelChange,
  onRowSelectionModelChange,
  rowSelectionModel,
  columns,
  paginationModel,
  isEmpty,
  isInvitedByConsultant,
  handleOpenConfirmUnassignment,
  selectedRows,
  confirmRows,
  getTogglableColumns,
  onSortBy,
}: Props) {
  const { translate } = useLocales();

  return (
    <DataGrid
      paginationMode="server"
      checkboxSelection
      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
      sx={{
        '& .MuiDataGrid-columnHeader': {
          lineHeight: '1.5',
          boxSizing: 'border-box',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '400',
        },
        '& .MuiDataGrid-virtualScroller': {
          ...(isEmpty && {
            height: 'calc(100vh - 600px)',
            marginTop: '50px',
          }),
        },
        '& .MuiDataGrid-cell:last-child': {
          position: 'sticky',
          right: 0,
          backgroundColor: '#fff',
          zIndex: 1,
        },
        '& .options-column-header .MuiDataGrid-columnHeaderTitle': {
          visibility: 'hidden', // Hide the header title for the "Options" column
        },
      }}
      rowCount={rowCount}
      isCellEditable={() => false}
      pagination
      autoHeight
      loading={isLoading}
      onPaginationModelChange={onPaginationModelChange}
      disableRowSelectionOnClick
      rows={gridData}
      columns={columns}
      getRowHeight={() => 'auto'}
      initialState={{
        pagination: {
          paginationModel,
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      slots={{
        // eslint-disable-next-line react/no-unstable-nested-components
        toolbar: () => (
          <GridToolbarContainer
            sx={{
              backgroundColor: '#F3F6F8',
            }}
          >
            <Stack
              spacing={1}
              flexGrow={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                '& .MuiButton-root': {
                  fontWeight: 'normal',
                  color: '#111',
                },
              }}
            >
              {isInvitedByConsultant && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenConfirmUnassignment}
                  startIcon={
                    <Iconify icon="humbleicons:user-remove" color="white" />
                  }
                >
                  <Typography variant="body2" color="white">
                    {String(
                      translate(
                        'adminDashboard.usersTable.toolbarButtons.removeFromConsulting'
                      )
                    )}
                  </Typography>
                </Button>
              )}
              {!!selectedRows?.length && (
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  startIcon={<Iconify icon="tabler:transfer" />}
                  onClick={confirmRows.onTrue}
                >
                  <Typography variant="body2">
                    {String(
                      translate(
                        'adminDashboard.usersTable.toolbarButtons.transferSelectedUsers',
                        {
                          selectedUsers: selectedRows?.length,
                        }
                      )
                    )}
                  </Typography>
                </Button>
              )}
              <GridToolbarColumnsButton lang="de" />
            </Stack>
          </GridToolbarContainer>
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        noRowsOverlay: () => <EmptyContent title="No Data" />,
        // eslint-disable-next-line react/no-unstable-nested-components
        noResultsOverlay: () => <EmptyContent title="No results found" />,
      }}
      slotProps={{
        columnsPanel: {
          getTogglableColumns,
        },
      }}
      onSortModelChange={onSortBy}
    />
  );
}
