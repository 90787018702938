import { Card, Typography } from '@mui/material';
import React from 'react';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type UsersTransferModalProps = {
  open: boolean;
  onClose: () => void;
  content?: React.ReactNode;
};

export default function UsersTransferModal({
  open,
  onClose,
  content,
}: UsersTransferModalProps) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose} title="Transfer Users">
      <Card sx={{ py: 1, px: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('adminDashboard.userTransferModal.title'))}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {String(translate('adminDashboard.userTransferModal.description'))}
        </Typography>
        {content}
      </Card>
    </DialogAnimate>
  );
}
