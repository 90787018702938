import { Navigate } from 'react-router-dom';
import React from 'react';
import Page from '#/components/shared/ui/Page';
import useLocales from '#/hooks/useLocales';
import ConsultantDashboard from '#/components/pages/Consultant/ConsultantDashboard';
import useAuth from '#/hooks/useAuth';
// import PADashboard from '#/components/pages/PartnerAdminDashboard/PADashboard';

export default function Consultant() {
  const { user } = useAuth();
  const { translate } = useLocales();

  const partnerRole = user?.partner?.role;

  // if (partnerRole === 'owner' || partnerRole === 'admin') {
  //   return <PADashboard />;
  // }
  if (partnerRole === 'client' || user?.partner === null) {
    return <Navigate to="/dashboard/home" />;
  }

  return (
    <Page title={String(translate('global.consultantDashboard.pageName'))}>
      <ConsultantDashboard />
    </Page>
  );
}
