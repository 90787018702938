import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';

export default function GeneralOverviewButton() {
  const { translate } = useLocales();
  return (
    <Stack direction="row" justifyContent="center">
      <Link to="/dashboard/home">
        <Button variant="contained">
          <Typography>
            {String(translate('adminDashboard.generalOverview'))}
          </Typography>
        </Button>
      </Link>
    </Stack>
  );
}
