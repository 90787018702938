import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-hot-toast';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidatePartnerQueries,
  useCancelUserInvitation,
  useDeleteUser,
  useResendUserInvitation,
} from '#/api/partnerQueries';
import DeleteConfirmationModal from '#/components/pages/Consultant/users-table/delete-confirmation-modal';
import ReportDeathModal from '#/components/shared/ReportDeathModal';
import { useCreateInvitation } from '#/api/successor';
import useAccountSwitch from '#/lib/hooks/useAccountSwitch';

type PopoverProps = {
  open: HTMLElement | null;
  onClose: VoidFunction;
  user_id: string;
  name: string;
  requestData?: any[];
  consultant_access_invitation_status?: string;
};

export const UnconfirmedUser = ({
  open,
  onClose,
  user_id,
  ...props
}: PopoverProps) => {
  const { translate } = useLocales();

  const { mutateAsync: resendInvitation, isLoading: isResending } =
    useResendUserInvitation();
  const { mutateAsync: cancelInvitation, isLoading: isCanceling } =
    useCancelUserInvitation();

  const handleResendInvitation = async () => {
    try {
      await resendInvitation({ id: user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.resend_invitation'))
        );
        invalidatePartnerQueries.consultantUserList();
        onClose();
      });
    } catch (error) {
      toast.error(
        String(translate('toast_notifications.error.resend_invitation'))
      );
    }
  };

  const handleCancelInvitation = async () => {
    try {
      await cancelInvitation({ id: user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.cancel_invitation'))
        );
        invalidatePartnerQueries.consultantUserList();
        onClose();
      });
    } catch (error) {
      console.error(error);
      toast.error(
        String(translate('toast_notifications.error.cancel_invitation'))
      );
    }
  };

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      arrow="right-top"
      sx={{ width: 'auto' }}
    >
      <MenuItem onClick={handleResendInvitation} sx={{ color: 'text.primary' }}>
        <Iconify
          icon={isResending ? 'gg:spinner-two' : 'fluent:send-16-filled'}
          color={(theme) => theme.palette.primary.main}
        />
        <Typography variant="body2">
          {String(translate('global.team.resendInvitation'))}
        </Typography>
      </MenuItem>
      <MenuItem sx={{ color: 'text.primary' }} onClick={handleCancelInvitation}>
        <Iconify
          icon={
            isCanceling ? 'gg:spinner-two' : 'material-symbols:cancel-outline'
          }
          color={(theme) => theme.palette.error.main}
          sx={{
            animation: isCanceling ? 'spin 1s linear infinite' : 'none',
          }}
        />
        <Typography variant="body2">
          {String(translate('global.team.cancelInvitation'))}
        </Typography>
      </MenuItem>
    </CustomPopover>
  );
};

export const ConfirmedUser: FC<PopoverProps> = ({
  open,
  onClose,
  user_id,
  name,
  requestData = [],
  consultant_access_invitation_status,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [reportDeathOpen, setReportDeathOpen] = useState(false);

  const handleOpenConfirmDelete = () => setConfirmDeleteOpen(true);
  const handleCloseConfirmDelete = () => setConfirmDeleteOpen(false);

  const handleOpenReportDeath = () => setReportDeathOpen(true);
  const handleCloseReportDeath = () => setReportDeathOpen(false);

  const { translate } = useLocales();
  const { mutateAsync: requestDataAccess } = useCreateInvitation();
  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { handleSwitchAccount, isSwitching } = useAccountSwitch();

  const handleDataRequest = async () => {
    try {
      await requestDataAccess({ invitations: requestData });
    } catch (error) {
      toast.error(
        String(translate('toast_notifications.error.requestDataAccess'))
      );
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser({ user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.deleteUser'))
        );
        onClose();
        invalidatePartnerQueries.consultantUserList();
      });
    } catch (error) {
      toast.error(String(translate('toast_notifications.error.deleteUser')));
    }
  };

  const handleSwitch = async (id: number) => {
    try {
      handleSwitchAccount(id);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const checkStatus = (status: string | undefined) => {
    switch (status) {
      case 'NOT_SENT':
        return handleDataRequest();
      case 'SENT':
        return null;
      case 'ACCEPTED':
        return handleSwitch(Number(user_id));
      default:
        return '';
    }
  };

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      arrow="right-top"
      sx={{ width: 'auto' }}
    >
      <MenuItem
        sx={{
          color: 'text.primary',
          opacity: consultant_access_invitation_status === 'SENT' ? 0.5 : 1,
        }}
        onClick={() => checkStatus(consultant_access_invitation_status)}
        disabled={consultant_access_invitation_status === 'SENT' || isSwitching}
      >
        <Iconify
          icon={isSwitching ? 'line-md:loading-twotone-loop' : 'mdi:eye'}
          color={(theme) => theme.palette.primary.main}
        />
        <Typography variant="body2">
          {consultant_access_invitation_status === 'NOT_SENT' &&
            String(translate('global.userDataAccess.request'))}
          {consultant_access_invitation_status === 'SENT' &&
            String(translate('global.userDataAccess.awaiting'))}
          {consultant_access_invitation_status === 'ACCEPTED' &&
            String(translate('global.userDataAccess.approved'))}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleOpenReportDeath();
        }}
        sx={{ color: 'text.primary' }}
      >
        <Iconify
          icon="f7:exclamationmark-octagon"
          color={(theme) => theme.palette.primary.main}
        />
        <Typography variant="body2">
          {String(translate('guest.reportDeath'))}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleOpenConfirmDelete}
        sx={{ color: 'text.primary' }}
      >
        <Iconify
          icon={
            isDeleting ? 'gg:spinner-two' : 'material-symbols:cancel-outline'
          }
          color={(theme) => theme.palette.error.main}
          sx={{
            animation: isDeleting ? 'spin 1s linear infinite' : 'none',
          }}
        />
        <Typography variant="body2">
          {String(translate('adminDashboard.deleteUser'))}
        </Typography>
      </MenuItem>
      <ReportDeathModal
        open={reportDeathOpen}
        onClose={handleCloseReportDeath}
        reportUserName={name}
        reportUserId={user_id}
      />
      <DeleteConfirmationModal
        open={confirmDeleteOpen}
        closeDialog={handleCloseConfirmDelete}
        onConfirm={handleDeleteUser}
        userInfo={{ name }}
      />
    </CustomPopover>
  );
};
