import {
  GridCellParams,
  GridRenderCellParams,
  GridTreeNode,
} from '@mui/x-data-grid';
import { $SpecialObject } from 'i18next/typescript/helpers';
import { TFunctionDetailedResult } from 'i18next';
import ColumnHeaderRender from './column-header-render';
import {
  RenderEmail,
  RenderLastLogin,
  RenderOwnedBy,
  RenderRowOptions,
  RenderServicePercentage,
  RenderUserDetails,
} from './user-table-row';

export const SERVICE_OPTIONS = [
  { value: 'full', label: 'Full' },
  { value: 'partial', label: 'Partial' },
  { value: 'none', label: 'None' },
];

export interface ExtendedParamsProps extends GridRenderCellParams {
  service?: string;
  option1?: VoidFunction;
  option2?: VoidFunction;
}

type Column = {
  translate: (
    text: any,
    options?: any
  ) =>
    | string
    | $SpecialObject
    | TFunctionDetailedResult<string | $SpecialObject>;
  handleShowFilter: (field: string) => void;
  filterRef: React.RefObject<HTMLDivElement>;
  activeFilterHeader: string | null;
  handleProgressRangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetFilter: () => void;
};

const usersColumnsConfig = ({
  translate,
  handleShowFilter,
  filterRef,
  activeFilterHeader,
  handleProgressRangeFilter,
  handleResetFilter,
}: Column) => [
  {
    field: 'category',
    disableColumnMenu: true,
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.category')
    ),
    filterable: false,
  },
  {
    field: 'name',
    disableColumnMenu: true,
    headerName: String(translate('adminDashboard.usersTable.tableHeads.name')),
    width: 200,
    hideable: false,
    resizable: true,
    renderCell: (
      params: GridCellParams<any, unknown, unknown, GridTreeNode>
    ) => <RenderUserDetails params={params} />,
  },
  {
    field: 'email',
    flex: 1,
    disableColumnMenu: true,
    headerName: String(translate('adminDashboard.usersTable.tableHeads.email')),
    minWidth: 200,
    editable: true,
    resizable: true,
    renderCell: (
      params: GridCellParams<any, unknown, unknown, GridTreeNode>
    ) => <RenderEmail params={params} />,
  },
  {
    field: 'invited_by',
    disableColumnMenu: true,
    flex: 1,
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.owned_by')
    ),
    minWidth: 120,
    editable: true,
    resizable: true,
    renderCell: (
      params: GridCellParams<any, unknown, unknown, GridTreeNode>
    ) => <RenderOwnedBy params={params} />,
  },
  {
    field: 'last_login',
    disableColumnMenu: true,
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.last_login')
    ),
    width: 150,
    editable: true,
    resizable: true,
    renderCell: (
      params: GridCellParams<any, unknown, unknown, GridTreeNode>
    ) => <RenderLastLogin params={params} />,
  },
  {
    field: 'pension_provision',
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.pension_provision')
    ),
    disableColumnMenu: true,
    sortable: false,
    renderHeader: () => (
      <ColumnHeaderRender
        field="total_percentage"
        headerName={String(
          translate('adminDashboard.usersTable.tableHeads.pension_provision')
        )}
        handleShowFilter={handleShowFilter}
        filterRef={filterRef}
        activeFilterHeader={activeFilterHeader}
        handleProgressRangeFilter={handleProgressRangeFilter}
        handleResetFilter={handleResetFilter}
      />
    ),
    filterable: false,
    width: 220,
    type: 'singleSelect',
    valueOptions: SERVICE_OPTIONS,
    resizable: true,
    renderCell: (params: ExtendedParamsProps) => (
      <RenderServicePercentage
        params={{
          params: params as ExtendedParamsProps,
          service: 'avg_percentage',
        }}
      />
    ),
  },
  {
    field: 'living_will',
    disableColumnMenu: true,
    sortable: false,
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.living_will')
    ),
    renderHeader: () => (
      <ColumnHeaderRender
        field="living_will_percentage"
        headerName={String(
          translate('adminDashboard.usersTable.tableHeads.living_will')
        )}
        handleShowFilter={handleShowFilter}
        filterRef={filterRef}
        activeFilterHeader={activeFilterHeader}
        handleProgressRangeFilter={handleProgressRangeFilter}
        handleResetFilter={handleResetFilter}
      />
    ),
    width: 220,
    type: 'singleSelect',
    valueOptions: SERVICE_OPTIONS,
    resizable: true,
    renderCell: (params: ExtendedParamsProps) => (
      <RenderServicePercentage
        params={{
          params: params as ExtendedParamsProps,
          service: 'living_will',
        }}
      />
    ),
  },
  {
    field: 'power_of_attorney',
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.power_of_attorney')
    ),
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <ColumnHeaderRender
        field="power_of_attorney_percentage"
        headerName={String(
          translate('adminDashboard.usersTable.tableHeads.power_of_attorney')
        )}
        handleShowFilter={handleShowFilter}
        filterRef={filterRef}
        activeFilterHeader={activeFilterHeader}
        handleProgressRangeFilter={handleProgressRangeFilter}
        handleResetFilter={handleResetFilter}
      />
    ),
    width: 220,
    type: 'singleSelect',
    valueOptions: SERVICE_OPTIONS,
    resizable: true,
    renderCell: (params: ExtendedParamsProps) => (
      <RenderServicePercentage
        params={{
          params: params as ExtendedParamsProps,
          service: 'power_of_attorney',
        }}
      />
    ),
  },
  {
    field: 'last_will',
    headerName: String(
      translate('adminDashboard.usersTable.tableHeads.last_will')
    ),
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => (
      <ColumnHeaderRender
        field="last_will_percentage"
        headerName={String(
          translate('adminDashboard.usersTable.tableHeads.last_will')
        )}
        handleShowFilter={handleShowFilter}
        filterRef={filterRef}
        activeFilterHeader={activeFilterHeader}
        handleProgressRangeFilter={handleProgressRangeFilter}
        handleResetFilter={handleResetFilter}
      />
    ),
    minWidth: 220,
    type: 'singleSelect',
    valueOptions: SERVICE_OPTIONS,
    resizable: true,
    renderCell: (params: any) => (
      <RenderServicePercentage
        params={{
          params,
          service: 'last_will',
        }}
      />
    ),
  },
  {
    field: '',
    sortable: false,
    headerName: 'Options',
    headerClassName: 'options-column-header',
    width: 50,
    hideable: false,
    resizable: false,
    disableColumnMenu: true,
    renderCell: (
      params: GridCellParams<any, unknown, unknown, GridTreeNode>
    ) => <RenderRowOptions params={params} />,
  },
];

export default usersColumnsConfig;
