import {
  Stack,
  Avatar,
  ListItemText,
  Typography,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { fDate } from '#/utils/formatTime';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/hooks/usePopover';
import {
  ConfirmedUser,
  UnconfirmedUser,
} from '#/components/pages/Consultant/users-table/popover-menus';

type ParamsProps = {
  params: GridCellParams;
};

interface ExtendedParamsProps extends ParamsProps {
  service?: string;
  option1?: VoidFunction;
  option2?: VoidFunction;
}

export function RenderUserDetails({ params }: ParamsProps) {
  return (
    <Stack direction="row" alignItems="center" sx={{ py: 2, width: 1 }}>
      <Avatar
        alt={params.row.first_name}
        src={params.row.coverUrl}
        variant="circular"
        sx={{ mr: 2 }}
      />
      <ListItemText
        disableTypography
        primary={
          <Typography
            noWrap
            color="inherit"
            variant="subtitle2"
            onClick={params.row.onViewRow}
            sx={{ cursor: 'pointer' }}
          >
            {`${params.row.first_name} ${params.row.last_name}`}
          </Typography>
        }
        sx={{ display: 'flex', flexDirection: 'column' }}
      />
    </Stack>
  );
}

export function RenderEmail({ params }: ParamsProps) {
  return (
    <Stack width={160}>
      <Tooltip title={params.row.email}>
        <Typography variant="body2" color="text.primary" noWrap>
          {params.row.email}
        </Typography>
      </Tooltip>
    </Stack>
  );
}

export function RenderOwnedBy({ params }: ParamsProps) {
  const { invited_by } = params.row;
  return (
    <Stack width={100}>
      <Typography variant="body2" color="text.primary" noWrap>
        {invited_by?.first_name} {invited_by?.last_name}
      </Typography>
    </Stack>
  );
}

export function RenderLastLogin({ params }: ParamsProps) {
  return <Stack>{fDate(params.row.last_login, 'dd MMMM yyyy', 'de')}</Stack>;
}

export const RenderServicePercentage: React.FC<{
  params: ExtendedParamsProps;
}> = ({ params }) => {
  const { service, params: p } = params;

  const hasPercentage = !!p?.row?.service_stats?.[`${service}`]?.percentage;

  const handlePercentage = () => {
    if (hasPercentage) {
      if (service === 'avg_percentage') {
        return p?.row?.service_stats?.[`${service}`]?.percentage;
      }
      return p?.row?.service_stats?.[`${service}`]?.service?.percentage;
    }
    return 0;
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ typography: 'caption', color: 'text.secondary' }}
    >
      <LinearProgress
        value={handlePercentage()}
        variant="determinate"
        color={
          (handlePercentage() <= 25 && 'error') ||
          (handlePercentage() < 50 && 'error') ||
          (handlePercentage() < 75 && 'warning') ||
          'success'
        }
        sx={{ mr: 2, height: 6, maxWidth: 80, minWidth: 80, borderRadius: 2 }}
      />
      <>{handlePercentage()}%</>
    </Stack>
  );
};

export const RenderRowOptions: React.FC<ParamsProps> = ({ params }) => {
  const popover = usePopover();

  const { id, last_login, first_name, last_name, email } = params.row;

  const name = `${first_name} ${last_name}`;

  return (
    <Stack>
      <IconButton
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      {last_login && (
        <ConfirmedUser
          open={popover.open}
          onClose={popover.onClose}
          user_id={id}
          name={name}
          requestData={[
            {
              first_name,
              last_name,
              email,
              type: 'CONSULTANT_ACCESS',
            },
          ]}
          consultant_access_invitation_status={
            params.row.consultant_access_invitation_status
          }
        />
      )}
      {!last_login && (
        <UnconfirmedUser
          open={popover.open}
          onClose={popover.onClose}
          user_id={id}
          name={name}
        />
      )}
    </Stack>
  );
};
