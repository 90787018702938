import { Card, Container } from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import React, { useState, useRef } from 'react';

import {
  invalidatePartnerQueries,
  useGetConsultantUserList,
  useTransferUsers,
} from '#/api/partnerQueries';
import useLocales from '#/hooks/useLocales';
import sortingModel from '#/components/pages/Consultant/users-table/sortingModel';
import useBoolean from '#/hooks/useBoolean';
import UsersTransferModal from '#/components/pages/Consultant/users-table/users-transfer-modal';
import UsersTransferForm from '#/components/pages/Consultant/users-table/users-transfer-form';
import ConfirmUnassignment from '#/components/pages/Consultant/users-table/confirm-unassignment';
import usersColumnsConfig from './users-columns-config';
import UsersDataGrid from './users-data-grid';

interface SelectedRow {
  id: number;
  first_name: string;
  last_name: string;
  invited_by?: any;
}

const HIDE_COLUMNS = {
  category: false,
};
const HIDE_COLUMNS_TOGGLABLE = ['category', 'name', ''];

export const SERVICE_OPTIONS = [
  { value: 'full', label: 'Full' },
  { value: 'partial', label: 'Partial' },
  { value: 'none', label: 'None' },
];

type ProgressRangeFilterProps = {
  min_percentage: number | null;
  max_percentage: number | null;
};

type Props = {
  search: string;
};

export default function Users({ search }: Props) {
  const [activeFilterHeader, setActiveFilterHeader] = useState<string | null>(
    null
  );
  const [progressRangeFilter, setProgressRangeFilter] =
    useState<ProgressRangeFilterProps>({
      min_percentage: null,
      max_percentage: null,
    });
  const [confirmUnassignment, setConfirmUnassignment] =
    useState<boolean>(false);
  const [sort_by, setSortBy] = useState<GridSortModel | null>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(HIDE_COLUMNS);
  const [selectedRowIds, setSelectedRowIds] = useState<
    SelectedRow[] | undefined
  >([]);

  const { translate } = useLocales();
  const { mutateAsync: removeTransfer } = useTransferUsers();

  const confirmRows = useBoolean();
  const filterRef = useRef<HTMLDivElement | null>(null);

  const handleOpenConfirmUnassignment = () => setConfirmUnassignment(true);
  const handleCloseConfirmUnassignment = () => {
    setConfirmUnassignment(false);
    setSelectedRowIds([]);
  };

  const handleResetFilter = () => {
    setProgressRangeFilter({
      min_percentage: null,
      max_percentage: null,
    });
  };

  const handleShowFilter = (field: string) => {
    setActiveFilterHeader((prev) => (prev === field ? null : field));
  };

  const handleSortBy = () => {
    const requestedSortField = sort_by?.[0]?.field;
    const requestedSortDirection = sort_by?.[0]?.sort ?? 'asc';
    return sortingModel?.find((model) => model.field === requestedSortField)
      ?.sort_by[requestedSortDirection];
  };

  const { data: userList, isLoading } = useGetConsultantUserList(
    paginationModel.page + 1,
    true,
    search || undefined,
    sort_by ? handleSortBy() : undefined,
    progressRangeFilter.min_percentage || undefined,
    progressRangeFilter.max_percentage || undefined,
    activeFilterHeader || undefined
  );

  const handlePaginationChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
  };

  const isInvitedByConsultant = selectedRowIds?.some((user) => user.invited_by);

  const handleProgressRangeFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setProgressRangeFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemoveFromConsulting = async () => {
    await removeTransfer({
      ids: selectedRowIds?.map((user) => user.id) ?? [],
      destination_consultant: null,
    }).then(() => {
      invalidatePartnerQueries.consultantUserList();
      setSelectedRowIds([]);
      handleCloseConfirmUnassignment();
    });
  };

  const columns = usersColumnsConfig({
    translate,
    handleShowFilter,
    filterRef,
    activeFilterHeader,
    handleProgressRangeFilter,
    handleResetFilter,
  });

  const getTogglableColumns = () =>
    columns
      .filter((column) => !HIDE_COLUMNS_TOGGLABLE.includes(column.field))
      .map((column) => column.field);

  const isEmpty = !userList?.results?.length;

  return (
    <Container maxWidth="lg">
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        {userList?.results && (
          <UsersDataGrid
            gridData={userList?.results || []}
            rowCount={userList?.count ?? 0}
            onPaginationModelChange={handlePaginationChange}
            onSortBy={(model) => setSortBy(model)}
            getTogglableColumns={getTogglableColumns}
            selectedRows={selectedRowIds}
            confirmRows={confirmRows}
            isInvitedByConsultant={isInvitedByConsultant}
            handleOpenConfirmUnassignment={handleOpenConfirmUnassignment}
            onRowSelectionModelChange={(newSelectionModel) => {
              const selectedUsers = userList?.results.filter((user) =>
                newSelectionModel.includes(user.id)
              );
              setSelectedRowIds(
                selectedUsers?.map((user) => ({
                  id: user.id,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  invited_by: user.invited_by,
                }))
              );
            }}
            rowSelectionModel={selectedRowIds?.map((user) => user.id) || []}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            columnVisibilityModel={columnVisibilityModel}
            paginationModel={paginationModel}
            columns={columns}
            isLoading={isLoading}
            isEmpty={isEmpty}
          />
        )}
      </Card>
      <UsersTransferModal
        open={confirmRows.value}
        onClose={confirmRows.onFalse}
        content={
          <UsersTransferForm
            selectedUsers={selectedRowIds}
            onClose={() => {
              confirmRows.onFalse();
              setSelectedRowIds([]);
            }}
          />
        }
      />
      <ConfirmUnassignment
        open={confirmUnassignment}
        onClose={handleCloseConfirmUnassignment}
        onConfirm={handleRemoveFromConsulting}
      />
    </Container>
  );
}
