import { Card, Stack, Typography, Button } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: VoidFunction;
};

export default function ConfirmUnassignment({
  open,
  onClose,
  onConfirm,
}: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Card sx={{ px: 2, py: 1 }}>
        <Typography variant="subtitle1">
          {String(translate('adminDashboard.confirmUnassignment.title'))}
        </Typography>
        <Typography variant="body2">
          {String(translate('adminDashboard.confirmUnassignment.description'))}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            mt: 2,
          }}
        >
          <Button variant="outlined" onClick={onClose} size="small" fullWidth>
            <Typography>
              {String(translate('adminDashboard.confirmUnassignment.onClose'))}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            size="small"
            fullWidth
          >
            <Typography>
              {String(
                translate('adminDashboard.confirmUnassignment.onConfirm')
              )}
            </Typography>
          </Button>
        </Stack>
      </Card>
    </DialogAnimate>
  );
}
